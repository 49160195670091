@font-face {
    font-family: 'circular-black';
    src:  url('../../fonts/circular/lineto-circular-black.eot');
    src:  url('../../fonts/circular/lineto-circular-black.eot') format('embedded-opentype'), url('../../fonts/circular/lineto-circular-black.ttf') format('truetype'), url('../../fonts/circular/lineto-circular-black.woff') format('woff');
} 

@font-face {
    font-family: 'circular-bold';
    src:  url('../../fonts/circular/lineto-circular-bold.eot');
    src:  url('../../fonts/circular/lineto-circular-bold.eot') format('embedded-opentype'), url('../../fonts/circular/lineto-circular-bold.ttf') format('truetype'), url('../../fonts/circular/lineto-circular-bold.woff') format('woff');
} 

@font-face {
    font-family: 'circular-book';
    src:  url('../../fonts/circular/lineto-circular-book.eot');
    src:  url('../../fonts/circular/lineto-circular-book.eot') format('embedded-opentype'), url('../../fonts/circular/lineto-circular-book.ttf') format('truetype'), url('../../fonts/circular/lineto-circular-book.woff') format('woff');
} 