/* VARS - AGENCY STARTER WEBSITE ===============================
======================================================= */
$white: #FFFFFF;
$black: #000000;

// General
$rethink-blue: rgba(0, 191, 255, 1);
$dark-grey: rgba(92, 92, 92, 1);
$light-grey: rgba(248, 248, 248, 1);


// FONTS ===============================
$baseFont: 'circular-book', sans-serif;
$circularBook: 'circular-book', sans-serif;
$circularBlack: 'circular-black', sans-serif;
$circularBold: 'circular-bold', sans-serif;
$baseFontRegular: 'Mont-Regular', sans-serif;
//$surveyorFont: 'HCo Surveyor SSm', sans-serif;
$surveyorFont: "Surveyor SSm A", "Surveyor SSm B";

// Container size
// smartphones (portrait view)
$screen-xs: 376px;

// Small tablets and large smartphones (landscape view)
$screen-sm: 576px;

// Small tablets (portrait view)
$screen-md: 768px;

// Tablets and small desktops
$screen-lg: 1024px;

// Large tablets and desktops
$screen-xl: 1200px;

// Super large
$screen-max: 1600px;

