@import '../../assets/scss/partials/vars';

.social-share {
    display: flex;
    justify-content: center;
    align-items: center;

    .btn-share {
        display: block;
        height: 40px;
        width: 40px;
        margin: 16px 8px 0;
        background: $rethink-blue;
        color: $white;
        border-radius: 20px;
        border: 0;

        img {
            display: block;
            margin: 12px auto 0;
        }

        &.linkedin {
            background: #0474B3;

            img {
                margin: 0px auto 0;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    
}